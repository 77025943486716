import { TMegaMenuJobsResponse, TMegaMenuJobTab } from "@@types/megaMenu";
import { jobsHostBase } from "@constants";
import { MegaMenu, MegaMenuEmpty } from "magneto365.ui";

const { Cards, SideCards } = MegaMenu;

export const jobsTabAdapter = ({ jobs, slug, t, key }: TMegaMenuJobTab): TMegaMenuJobsResponse => {
  const url = `${jobsHostBase}/${slug}`;
  return {
    label: t(`header:jobs.tabs.${key}`),
    url,
    content:
      jobs.length == 0 ? (
        <MegaMenuEmpty
          title={t(`header:jobs.waiting.title`)}
          subtitle={t(`header:jobs.waiting.subtitle`)}
          imageData="waiting"
        />
      ) : (
        <Cards
          jobs={jobs.map(({ counter, h1, slug, iconUrl, logoUrl }) => ({
            title: h1,
            quotas: counter,
            href: `${jobsHostBase}/trabajos/${slug}`,
            logo: logoUrl,
            icon: iconUrl
          }))}
          maxCards={jobs.length}
          action={{ label: t(`header:jobs.viewAll`), url }}
          title={t(`header:jobs.tabs.${key}`)}
          actionTitle={{ label: t(`header:jobs.actionTitle.${key}`), url }}
        />
      )
  };
};

export const jobsSideTabAdapter = ({
  jobs,
  slug,
  t,
  key,
  onSelectCard,
  selected
}: TMegaMenuJobTab): TMegaMenuJobsResponse => {
  const url = `${jobsHostBase}/${slug}`;
  return {
    label: t(`header:jobs.tabs.${key}`),
    url,
    content:
      jobs.length == 0 ? (
        <MegaMenuEmpty
          title={t(`header:jobs.waiting.title`)}
          subtitle={t(`header:jobs.waiting.subtitle`)}
          imageData="waiting"
        />
      ) : (
        <SideCards
          wrapperProps={{
            title: t(`header:jobs.tabs.${key}`),
            actionTitle: { label: t(`header:jobs.actionTitle.${key}`), url },
            maxCards: jobs.at(selected ?? 0)?.landings?.length ?? 0,
            jobs:
              jobs.at(selected ?? 0)?.landings?.map(({ counter, h1, slug }) => ({
                title: h1,
                quotas: counter,
                href: `${jobsHostBase}/trabajos/${slug}`
              })) ?? []
          }}
          sideProps={{
            maxCards: jobs.length,
            jobs: jobs.map(({ h1, counter, slug }) => {
              const url = `${jobsHostBase}/${slug}`;
              return {
                title: h1,
                href: url,
                quotas: counter,
                onClick: () => ({})
              };
            }),
            onSelectCard: onSelectCard,
            action: { label: t(`header:jobs.viewAll`), url }
          }}
        />
      )
  };
};
